.DevelopersPage{

  .HeroContent{
    margin-bottom: 35px;
  }

  .HeroContent__subtitle{
    max-width: 630px;
  }

  .PartnerLogos{
    max-width: unset;
  }

  .FeaturePoints{
    margin-bottom: 100px;
  }

  .Quotes{
    margin-top: 120px;
    margin-bottom: 150px;

    @include breakpoint("mobile", max) {
      margin-top: 80px;
    }
  }

  .MainCTAButton{
    margin-right: 32px;
    width: 294px;
  }

  &__ctas{
    margin-bottom: 90px;

    @include breakpoint("mobile", max) {
      margin-bottom: 80px;
    }
  }
}


.DeveloperCards{
  display: flex;
  flex-direction: row;
  margin-bottom: 100px;
  margin-left: -17px;
  margin-right: -17px;

  @include breakpoint("tablet-wide", max){
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;

    .DeveloperCard{
      margin-bottom: 18px;
    }
  }

  @include breakpoint("mobile", max) {
    margin-bottom: 20px;
    flex-wrap: wrap;
  }
}

.DeveloperCard{
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1B1F39;
  height: 400px;
  max-width: 296px;
  padding-top: 35px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  margin-left: 17px;
  margin-right: 17px;
  width: 296px;
  flex-basis: 296px;

  @include breakpoint("tablet-wide", max){
    width: 260px;
    flex-basis: 260px;
    max-width: 260px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 18px;
  }

  @include breakpoint("mobile", max) {
    height: 216px;
    width: 160px;
    flex-basis: 160px;
    max-width: 160px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    padding-top: 18px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 13px;
  }

  &:last-of-type{
    margin-right: 0;
  }

  &__title{
    font-size: 24px;
    line-height: 29px;
    color: #847DFF;
    margin-bottom: 40px;
    height: 58px;

    @include breakpoint("mobile", max) {
      font-size: 14px;
      line-height: 17px;
      height: 50px;
      flex-basis: 50px;
      margin-bottom: 4px;

    }
  }

  &__subtitle{
    font-size: 16px;
    line-height: 19px;
    color: #9EA2D4;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__cta{
    margin-top: auto;
  }
}

.DevelopersMainnet4{
  display: flex;
  flex-direction: row;
  margin-bottom: 160px;
  padding-top: 60px;

  @include breakpoint("mobile", max) {
    flex-direction: column;
    margin-bottom: 110px;
  }

  &__imagery{
    flex: 1;
    padding-top: 28px;


    @include breakpoint("mobile", max) {
      display: flex;
      justify-content: center;
    }

    img{
      width: 100%;
      max-width: 503px;
    }
  }

  &__info{
    flex: 1;
    padding-left: 33px;

    @include breakpoint("mobile", max) {
      padding-left: 0;
    }

    .Button{
      margin-top: 44px;

      @include breakpoint("mobile", max) {
        width: 217px;
      }
    }
  }

  &__rows{
    display: flex;
    flex-direction: column;
  }

  &__row{
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 1px solid #3A3B4D;

    div{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    p{
      font-size: 18px;
      line-height: 22px;
      color: #7A7DA3;
      margin-bottom: 8px;

      @include breakpoint("mobile", max) {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}