.NodesMap{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1366px;
  height: calc(50vh + 50px);
  max-height: 800px;
  width: 100%;

  iframe{
    width: 100%;
    border: none;
    outline: none;
    height: 100%;
    max-width: 1366px;
  }
}

.NodesMapLegend{
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  margin-bottom: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

.NodesMapLegendItem{
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #7A7DA3;
  margin-right: 12px;
  height: 30px;

  &:last-child{
    margin-right: 0;
  }

  &:before{
    content: "";
    width: 21px;
    height: 21px;
    //background-image: url("./images/map/dot_green_1@2x.png");
    background-size: 21px 21px;
    display: block;
    margin-right: 6px;
  }

  &--gn{
    &:before{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMCAYAAADHl1ErAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD4ElEQVR4nO2cTWrbQBTH/xE2DqEYQiiYdtFNtTDeeZELxAfoCXqBNqdpe4DmBDlAeoEstBNeuJsuXAwhGEQwMQ6mjPrkyPqwrNHM09f8wBgvMiP98jSaedK8E5SA7bg9AGcAOgBO6bubcSQbAC8Anul7NRuP1txHzyLMdlwLwBv6CFGWoqa3QhyAJ/GZjUdbRe2molWY7bhCUJ9EcSDEebPx6ElXX1qE2Y4rJF0ccZnpQly+j7PxyFPdvlJhFRAVRbk4JcJsxxXj0lsAPRXtaUDcHB5m49GqaNOFhdmOK0SdazpR1Sxn49FDkTalhdHUYFDhqEpDRNtCdkoiJYzufgOF0wNutiQt9900tzAa2AdVtCDBIu8NIZewhskKyCXtaGENlRVwtLSjhDVcVsBR0jIH7dAA33QGdK7ywkJTh7YwoHOWjrA6Tx1ksLICJFUGzeDrNilVQY/OPZFEYbQ2rMtyRwfn5CBGWoSlGm4RiQ5iwmgK0cZLMUqPXOyRFGEXpR1i9Yi52BNGRquS/KsC3WiURSPMRFecPSc7YTTLNdEVpxteAYQjLDbAGeJu/MU3PTf8WJIfcTBXACYALhP+cWJBfA/gDsAv+l0Gv8Vzzw51zPXcMMo1gM8Z0d0nmROSdQPgWwnHKhx5VugHJ+8B3AL4mnMo6NPf3FIbnPiOAmGJywBNDOmEhwWaV9FGXnxHJ5TO+MDUaRBZqm4w4hL9BGCuqL0s/ljM0fVD8d24T21ycWbRq0YcXGu6hIbUNgcdi97P0k2f7oa6yLrTquKUK8KuNJ9QMJfTjR9hHMuhSUP66HLl6y8b0gfbAw6O8YVlLdymJ0JKMMJyYoTlhEsYR0qGJe3DJey+IX34wjYM/dw1pI+NRdtQdKM7U+pRH7p5sWjvjm6CTKkubpjGsGeuCAOllaca2p0ypqz9CCv8sn8OviiOBI/a5GJl0fvq2neBEXNKxaiQ5lFbXNnWrXAVTCs4o2xKaeUil6eKNvLiOwqEadsul8KcTvh7zmjz6G848/gBvqNO+EcJiMH6Z00e5PqOdq+d2477rsQHulVH7Pb9i8jSqKz/XB3YudkJo41KHMukurEJb+KKLr4f224ngT0ne8Jo64iJslc20e00SekdE2WvxFzEhJFR9gIaFWSdtFkrLYFYaF90Q0h0kCiMdt8vWyxrmVaBIDVFTbvv23hprg9VHsjK6S8YMxlVYEvnnMpBYZT6OdhAw8gsz5D51IhmuW2QdlRZBrNJ/j9qN8kHmDIMptCH3kIfAaaUjASmWJEkphyWBKbgmiSmpJ8kpmikJKYsqSSm8G1BaltaGcA/EFBH1TRFhikAAAAASUVORK5CYII=');
    }
  }

  &--en{
    &:before{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMCAYAAADHl1ErAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD30lEQVR4nO2cTWrbQBTH/xE2DnExhFAw7aKbgvAFsvMqPkBP0Au0OU3bAzQnyAHSlXa5gDF004WLIQSDiEyMgymjPjmyPixrNPP0NT8wxovMSL88jWaeNO8EJeA5dg/AGYAOgFP67mYcyQbAC4Bn+l71x7M199GzCPMc2wLwhj5ClKWo6a0QB+BJfPrj2VZRu6loFeY5thA0IFEcCHFufzx70tWXFmGeYwtJF0dcZroQl+9jfzxzVbevVFgFREVRLk6JMM+xxbj0FkBPRXsaEDeHh/54tiradGFhnmMLUeeaTlQ1y/549lCkTWlhNDUYVjiq0hDRtpCdkkgJo7vfUOH0gJstSct9N80tjAb2YRUtSLDIe0PIJaxhsgJySTtaWENlBRwt7ShhDZcVcJS0zEE7NMA3nSGdq7yw0NShLQzpnKUjrM5TBxmsrABJlUEz+LpNSlXQo3NPJFEYrQ3rstzRwTk5iJEWYamGW0Sig5gwmkK08VKM0iMXeyRF2EVph1g9Yi72hJHRqiT/qkA3GmXRCDPRFWfPyU4YzXJNdMXphlcA4QiLDXCGuBt/8U3PDT+W5EcczBWACYDLhH+cWBDfA7gD8It+l8Fv8dyzQx1zPTeMcg3gc0Z0D0jmhGTdAPhWwrEKR64V+sHJewC3AL7mHAoG9De31AYnvqNAWOIyQBMjOuFRgeZVtJEX39EJpTM+MHUaRJaqG4y4RD8BmCtqL4s/FnN0/VB8Nx5Qm1ycWfSqEQfXmi6hEbXNQcei97N0M6C7oS6y7rSqOOWKsCvNJxTM5XTjRxjHcmjSkD66XPn6y4b0wfaAg2N8YVkLt+mJkBKMsJwYYTnhEsaRkmFJ+3AJu29IH76wDUM/dw3pY2PRNhTd6M6UutSHbl4s2rujmyBTqosbpjHsmSvCQGnlqYZ2p4wpaz/CCr/sn4MviiPBpTa5WFn0vrr2XWDEnFIxKqS51BZXtnUrXAXTCs4om1JaucjlqaKNvPiOAmHatsulMKcT/p4z2lz6G848foDvqBP+UQJisP5Zkwe5vqPda+eeY78r8YFu1RG7ff8isjQq6z9XB3ZudsJooxLHMqlubMKbuKKL78e220lgz8meMNo6YqLslU10O01SesdE2SsxFzFhZJS9gEYFWSdt1kpLIBbaF90QEh0kCqPd98sWy1qmVSBITVHT7vs2XprrQ5UHsnL6C8ZMRhXY0jmnclAYpX4ONtAwMsszZD41olluG6QdVZbBbJL/j9pN8gGmDIMp9KG30EeAKSUjgSlWJIkphyWBKbgmiSnpJ4kpGimJKUsqiSl8W5DallYG8A/lLke+kfH43wAAAABJRU5ErkJggg==');
    }
  }

  &--vn{
    &:before{
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEwAAABMCAYAAADHl1ErAAAACXBIWXMAABYlAAAWJQFJUiTwAAAD30lEQVR4nO2cTWrbQBTH/xI2DqEYQiiYdtFNN15qkwvEB+hai16gzWnaHqBZeJ0DpBfIxktvuunCxRCCQQST4GCVUZ8cWR+WNZp5+pofGONFZqRfnkYzT5pnoQJ8xx0AOAXQA3BC3/2cI9kAeAHwRN9razZ95j56FmG+49oA3tBHiLIVNb0V4gA8io81m24VtZuJVmG+4wpBQxLFgRDnWbPpo66+tAjzHVdIOj/iMtOFuHwfrNnUU92+UmE1EBVHuTglwnzHFePSWwADFe1pQNwc7q3ZdF226dLCfMcVos40nahqVtZsel+mTWlhNDUY1TiqshDRtpSdkkgJo7vfSOH0gJstSSt8Ny0sjAb2UR0tSLAsekMoJKxlskIKSTtaWEtlhRwt7ShhLZcVcpS03EE7MsC3nRGdq7ywyNShK4zonKUjrMlTBxnsvADJlEEz+KZNSlUwoHNPJVUYrQ2bstzRwRk5SJAVYZmGO0Sqg4QwmkJ08VKMMyAXe6RF2Hllh1g/Ei72hJHRuiT/6kA/HmXxCDPRlWTPyU4YzXJNdCXpR1cA0QhLDHCGpJtg8U3PDT9W5EcczCWACYCLlH+cWBDfAbgF8It+V8Fv8dyzRx1zPTeMcwXgc050D0nmhGRdA/hWwbEKR54d+cHJewA3AL4WHAqG9Dc31AYngaNQWOoyQBNjOuFxieZVtFGUwJFF6YwPTJ2GkaXqBiMu0U8AForay+OPzRxdPxTfjYfUJhenNr1qxMGVpktoTG1z0LPp/SzdDOluqIu8O60qTrgi7FLzCYVzOd0EEcaxHJq0pI8+V77+oiV9sD3g4BhfWNbCXXoipAQjrCBGWEG4hHGkZFjSPlzC7lrSRyBsw9DPbUv62Ni0DUU3ujOlHvWhmxeb9u7oJsyU6uKaaQx74oowUFp5rqHdOWPKOoiw0i/7F+CL4kjwqE0u1ja9r659FxixoFSMCmketcWVbd0KV+G0gjPK5pRWLnN5qmijKIGjUJi27XIZLOiEvxeMNo/+hjOPHxI46kV/VIAYrH825EFu4Gj32rnvuO8qfKBbd8Ru37+ILY2q+s81gZ2bnTDaqMSxTGoam+gmrvji+6HrdlLYc7InjLaOmCh7ZRPfTpOW3jFR9krCRUIYGWUvoFFDntM2a2UlEEvti24JqQ5ShdHu+1WHZa2yKhBkpqhp930XL83nQ5UH8nL6S8ZMRh3Y0jlnclAYpX4ONtAycssz5D41olluF6QdVZbBbJL/j9pN8iGmDIMp9KG30EeIKSUjgSlWJIkphyWBKbgmiSnpJ4kpGimJKUsqiSl8W5LGllYG8A9dsEgTL3+SFAAAAABJRU5ErkJggg==');
    }
  }

  &__title{
    line-height: 14px;
    height: 12px;
    margin-top: 1px;
  }
}