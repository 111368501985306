.PersonCards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -17px;
  margin-right: -17px;

  @include breakpoint("mobile", max) {
    margin-left: -5.5px;
    margin-right: -5.5px;
  }
}

.PersonCard{
  display: flex;
  flex-direction: column;
  width: 186px;
  margin-left: 17px;
  margin-right: 17px;
  margin-bottom: 60px;

  @include breakpoint("mobile", max) {
    width: 104px;
    margin-bottom: 30px;
    margin-left: 5.5px;
    margin-right: 5.5px;
  }

  &__headshot{
    width: 100%;
  }

  &__name{
    margin-top: 26px;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;

    @include breakpoint("mobile", max) {
      font-size: 12px;
      line-height: 18px;
      margin-top: 9px;
    }
  }

  &__title{
    margin-top: 2px;
    font-size: 13px;
    line-height: 22px;
    text-align: center;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 18px;
    }
  }

  &__logo-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    margin-top: 23px;

    @include breakpoint("mobile", max) {
      margin-top: 3px;
      height: 30px;
    }
  }

  &__logo{
    align-self: center;
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
}