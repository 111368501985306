.AboutPage{
  .HeroContent{
    margin-bottom: 109px;

    @include breakpoint("mobile", max) {
      margin-bottom: 50px;
    }
  }

  .HeroContent__title{
    max-width: 850px;
  }

  .SectionTitle{
    margin-bottom: 45px;

    @include breakpoint("mobile", max) {
      margin-bottom: 30px;
    }
  }

  .HeroContent__subtitle{
    font-size: 30px;
    line-height: 36px;
    color: #7A7DA3;
    font-weight: normal;
    max-width: 956px;
    margin-top: 60px;
    opacity: 1;

    @include breakpoint("mobile", max) {
      font-size: 12px;
      line-height: 14.4px;
      margin-top: 14px;
    }
  }

  &__leadership-team-section{
    margin-bottom: 120px;

    @include breakpoint("mobile", max) {
      margin-bottom: 50px;
    }
  }

  &__media-advisors-section{
    margin-bottom: 60px;
  }

  &__blockchain-advisors-section{
    margin-bottom: 60px;
  }

  &__investors-section{
    margin-bottom: 120px;

    .PartnerLogos{
      max-width: calc(100% + 26px);
    }
  }
}