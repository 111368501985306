.job-posting-page {
  padding: 20px;
  font-family: Arial, sans-serif;

  .job-posting-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .job-details {
    margin-bottom: 20px;

    .job-position {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .job-company-location {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .job-description {
      margin-bottom: 15px;
      line-height: 1.6;
    }

    .job-salary {
      font-size: 16px;
      margin-bottom: 15px;
    }

    .job-application a {
      color: #0066cc;
      text-decoration: none;
    }

    .job-application a:hover {
      text-decoration: underline;
    }
  }

  .job-notice {
    font-size: 14px;
    line-height: 1.6;
    color: white;
    opacity: 0.8;
  }
}
