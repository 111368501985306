.Button{
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 0;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  height: 36px;
  padding-left: 12px;
  padding-right: 12px;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  min-width: 90px;
  font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;

  &--green{
    background-color: $color-green;
    color: $color-background;

    &:hover{
      background-color: transparentize($color-green, 0.1);
    }

    &:active{
      background-color: transparentize($color-green, 0.2);
    }
  }

  &--grey{
    background-color: #3D4463;
    color: white;

    &:hover{
      background-color: transparentize(#3D4463, 0.1);
    }

    &:active{
      background-color: transparentize(#3D4463, 0.2);
    }
  }

  &--small{
    height: 34px;
    font-size: 12px;
    min-width: 70px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &--large{
    height: 52px;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;

    @include breakpoint("mobile", max) {
      font-size: 14px;
      height: 46px;
    }
  }

  &--xlarge{
    height: 66px;
    font-size: 20px;
    line-height: 24px;
  }

  .Loader{
    position: absolute;
  }
}

.BackButton{
  position: relative;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  border-radius: 8px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  height: 36px;
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;
  background-color: transparent;
  font-family: "Gilroy", -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
  color: $color-text-dark;

  svg{
    margin-right: 6px;
  }

}

.TextLinkButton{
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  color: $color-green;
  cursor: pointer;

  @include breakpoint('mobile'){
    font-size: 11px;
    line-height: 13px;
  }
}

.DownloadButton{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
  width: 120px;
  border: 1px solid #847DFF;
  border-radius: 15px;
  font-size: 13px;
  line-height: 15px;
  color: white;
  cursor: pointer;

  *{
    cursor: inherit;
  }

  &__icon-wrapper{
    width: 15px;
    height: 15px;
    margin-left: 14px;
    margin-right: 12px;
  }

  span{
    text-align: center;
    flex: 1;
    margin-right: 28px;
  }

  img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &:hover{
    border-color: $color-green;
  }
}