.PartnerLogos {
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  margin-top: 35px;
  margin-left: -13px;
  margin-right: -13px;

  @include breakpoint("mobile", max) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.PartnerLogo{
  height: 138px;
  width: 138px;
  margin-bottom: 30px;
  margin-right: 13px;
  margin-left: 13px;

  @include breakpoint("mobile", max) {
    width: 74px;
    height: 74px;
    margin-right: 6px;
    margin-left: 6px;
    margin-bottom: 16px;
  }
}
