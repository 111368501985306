.DocsPage{
  .DeveloperCards{
    flex-wrap: wrap;
  }

  .DeveloperCard{
    margin-bottom: 50px;
  }

  .HeroContent{
    margin-bottom: 100px;

    @include breakpoint("mobile", max) {
      margin-bottom: 48px;
    }
  }

  .HeroContent__subtitle{
    max-width: 520px;
  }

  &__papers-section{
    margin-top: 130px;

    @include breakpoint("mobile", max) {
      margin-top: 50px;
    }

    .SectionTitle{
      margin-bottom: 30px;
    }
  }
}

.MainDocCards{
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @include breakpoint("mobile", max) {
    flex-direction: column;
    align-items: center;
  }
}

.MainDocCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  max-width: 354px;

  @include breakpoint("mobile", max) {
    margin-bottom: 70px;
  }

  &__image{
    height: 228px;

    @include breakpoint("mobile", max) {
      height: 180px;
    }
  }

  &__title{
    font-size: 25px;
    line-height: 31px;
    margin-bottom: 11px;
    text-align: center;

    @include breakpoint("mobile", max) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__subtitle{
    font-size: 18px;
    line-height: 21px;
    color: #7A7DA3;
    margin-bottom: 30px;
    text-align: center;

    @include breakpoint("mobile", max) {
      font-size: 16px;
      line-height: 19px;
    }
  }

  &__link{
    margin-left: 10px;
    margin-right: 10px;
  }
}