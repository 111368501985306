$breakpoints: (
  // ===================
  // ANYTIME YOU WANT A MOBILE LAYOUT
  // ===================
  "mobile":       768px,

  // ===================
  // USE ONLY IF YOU NEED SPECIFIC CONTROL
  // ===================
  "phone":        501px,
  "phone-wide":   667px,
  "tablet":       768px,
  "tablet-wide":  1024px,
  "desktop":      1248px,
  "desktop-wide": 1440px,
);

@mixin breakpoint($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  } @else {
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

.desktop-only{
  @include breakpoint('mobile'){
    display: none !important;
  }
}

.mobile-only{
  @include breakpoint('mobile', min){
    display: none !important;
  }
}