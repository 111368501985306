.SectionTitle{
  font-size: 20px;
  font-weight: normal;
  line-height: 24px;
  color: $color-text-dark;
  letter-spacing: 4px;
  text-align: left;
  text-transform: uppercase;

  @include breakpoint("mobile", max){
    font-size: 14px;
  }

  &--center{
    text-align: center;
  }

  &--weight-normal{
    font-weight: normal;
  }

  &--weight-600{
    font-weight: 600;
  }

  &--weight-bold{
    font-weight: bold;
  }
}