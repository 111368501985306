.EcosystemPage{

  .HeroContent{
    margin-bottom: 35px;
  }

  .HeroContent__subtitle{
    max-width: 630px;
  }

  .Tags{
    margin-bottom: 16px;
  }

  &__projects-section{
    margin-top: 60px;
  }
}

.ProjectCards{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 160px;
  margin-left: -17px;
  margin-right: -17px;

  .ProjectCard{
    margin-bottom: 36px;
  }

  @include breakpoint("tablet-wide", max){
    flex-wrap: wrap;
    margin-left: -7.5px;
    margin-right: -7.5px;

    .ProjectCard{
      margin-bottom: 18px;
    }
  }

  @include breakpoint("mobile", max) {
    margin-bottom: 80px;
    flex-wrap: wrap;
  }
}

.ProjectCard{
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #1B1F39;
  height: 480px;
  max-width: 296px;
  padding-top: 0;
  padding-bottom: 25px;
  margin-left: 17px;
  margin-right: 17px;
  width: 296px;
  flex-basis: 296px;


  @include breakpoint("mobile", max) {
    height: 300px;
    width: 160px;
    flex-basis: 160px;
    max-width: 160px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    padding-top: 18px;
    padding-left: 13px;
    padding-right: 13px;
    padding-bottom: 13px;
  }

  &:last-of-type{
    margin-right: 0;
  }

  &__image{
    width: 100%;
    margin-bottom: 23px;
  }

  &__title{
    font-size: 24px;
    line-height: 29px;
    color: #847DFF;
    height: 58px;
    padding-left: 25px;
    padding-right: 25px;


    @include breakpoint("mobile", max) {
      font-size: 14px;
      line-height: 17px;
      height: 30px;
      flex-basis: 30px;
      margin-bottom: 4px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__subtitle{
    font-size: 16px;
    line-height: 19px;
    color: #9EA2D4;
    padding-left: 25px;
    padding-right: 25px;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 12px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__cta{
    padding-left: 25px;
    padding-right: 25px;
    margin-top: auto;
  }
}
