.WalletPage{
  .PageBackground{
    opacity: 0.5;
  }

  .HeroContent{
    margin-bottom: 130px;

    @include breakpoint("mobile", max) {
      margin-bottom: 80px;
    }
  }

  .HeroContent__title {
    max-width: 800px;
  }

  .HeroContent__subtitle{
    max-width: 600px;
  }

  &__platforms-section{
    margin-bottom: 120px;

    .SectionTitle{
      margin-bottom: 30px;
    }
  }
}

.WalletCards{
  display: flex;
  flex-direction: row;
  margin-left: -15px;
  margin-right: -15px;
  flex-wrap: wrap;
}

.WalletCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
  height: 295px;
  background-color: #1B1F39;
  flex: 1;
  padding-bottom: 30px;

  @include breakpoint("mobile", max) {
    flex-direction: row;
    padding-bottom: 0px;
    padding-right: 27px;
    padding-left: 20px;
    height: 100px;
    flex-basis: 350px;
    max-width: 350px;
    margin-bottom: 19px;
  }

  &__icon-wrapper{
    height: 90px;
    margin-top: 55px;

    @include breakpoint("mobile", max) {
      height: 55px;
      width: 68px;
      margin-top: 0;
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__name{
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    text-align: center;
    margin-top: auto;

    @include breakpoint("mobile", max) {
      font-size: 14px;
      line-height: 17px;
      margin-left: auto;
      text-align: right;
      margin-top: unset;
    }
  }
}