.Quote{
  display: flex;
  flex-direction: column;
  justify-content: left;
  max-width: 968px;

  &__body{
    font-size: 30px;
    line-height: 36px;
    color: $color-text-dark;
    margin-bottom: 34px;
    text-align: left;

    @include breakpoint('mobile'){
      font-size: 18px;
      line-height: 21px;
    }
  }

  &__name{
    font-size: 18px;
    color: $color-text-dark;
    margin-bottom: 24px;

    @include breakpoint('mobile'){
      font-size: 12px;
    }
  }

  &__logo{
    height: 54px;
    align-self: start;
  }

  &--center{
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    *{
      text-align: center;
    }

    .Quote__logo{
      align-self: center;
    }

    .Quote__body{
      text-align: center;
    }
  }
}

.Quotes{
  width: 100%;

  .carousel{
    padding-top: 24px;
  }

  .control-dots{
    display: flex;
    flex-direction: row;
    bottom: unset !important;
    top: 0 !important;
    margin: 0 !important;
  }

  .carousel .slide img{
    width: unset;
  }

  &--center{
    .control-dots{
      justify-content: center;
    }
  }
}

.QuoteIndicator{
  width: 40px;
  height: 22px;
  margin-left: 6px;
  margin-right: 6px;
  cursor: pointer;

  &__inner{
    width: 100%;
    height: 2px;
    background-color: rgba(255,255,255,0.15);
    cursor: inherit;
  }

  &--selected{
    .QuoteIndicator__inner{
      background-color: $color-green;
    }
  }
}