.NewsArticles{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100%;
  margin-left: -10px;
  margin-right: -10px;
}

.NewsArticle{
  display: flex;
  flex-direction: column;
  max-width: 350px;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 60px;

  &__source{
    font-size: 16px;
    font-weight: normal;
    line-height: 19px;
    color: white;
    margin-bottom: 8px;
    text-align: center;

    @include breakpoint('mobile'){
      font-size: 14px;
      line-height: 16.8px;
    }
  }

  &__date{
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    color: $color-text-dark;
    margin-bottom: 12px;
    text-align: center;

    @include breakpoint('mobile'){
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__title{
    font-size: 25px;
    line-height: 32px;
    color: white;
    margin-bottom: 18px;
    text-align: center;

    @include breakpoint('mobile'){
      font-size: 22px;
      line-height: 28px;
    }
  }
}