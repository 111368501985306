.PageBackground{
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  width: 100%;

  max-width: $max-background-width;
  /* left: auto; */
  /* right: auto; */
  margin-left: auto;
  margin-right: auto;

  &--inline{
    position: unset;
    z-index: unset;
  }
}