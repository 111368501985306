.official-links-page{
  color: #7A7DA3;
  font-size: 18px;

  .HeroContent__subtitle {
    font-size: 20px;
    line-height: 24px;
    color: #7A7DA3;
    font-weight: normal;
    max-width: 956px;
    opacity: 1;
  }

  h1{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2{
    margin-bottom: 8px;
    margin-top: 35px;

    font-size: 20px;
    font-weight: bold;
    line-height: 24px;
    color: #7A7DA3;
    letter-spacing: 4px;
    text-align: left;
    text-transform: uppercase;

    @include breakpoint("mobile", max) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  a{
    color: $color-teal;
    font-weight: 600;
  }

  ul{
    list-style-type: disk;
    //padding: 0;
    //margin: 0;
    padding-left: 15px
  }

  li{
    margin-bottom: 10px;
    list-style: disc;
    font-weight: 600;

    a{
      word-break: break-all;
    }
  }
}