.NewsBanner{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  width: 100%;
  max-width: 1366px;
  margin-left: auto;
  margin-right: auto;
  background-color: #3DEBA8;

  &__content{
    display: flex;
    flex-direction: row;
    align-self: center;
    margin-left: 50px;
    margin-right: 50px;
  }

  &__title{
    font-size: 16px;
    font-weight: bold;
    color: #111114;
    line-height: 21px;
  }

  &__source{
    font-size: 16px;
    font-weight: normal;
    color: #111114;
    line-height: 21px;
    margin-left: 6px;
  }

  .marquee{
    justify-content: center;
  }
}