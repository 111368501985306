$color-background-home: #09090D;
$color-background: #141628;
$color-navbar-background: #0B0B0D;

$color-text-dark: #7A7DA3;
$color-text-light: white;

$color-green: #3DEBA8;
$color-red: #FF2685;

$color-teal: #00A99D;

$color-border: #2C2F40;

$modal-border-radius: 6px;

$color-input-background: transparent;
$color-input-focus: $color-green;

$color-input-border: $color-border;

$color-input-text: white;

$color-input-placeholder: $color-text-dark;

$max-width: 1286px;
$max-background-width: 1366px;

.color--green{
  color: $color-green;
}