.Roadmap{
  display: flex;
  flex-direction: row;

  @include breakpoint("mobile", max) {
    flex-direction: column;
  }
}

.RoadmapColumn{
  flex: 1;

  @include breakpoint("mobile", max) {
    margin-bottom: 30px;
  }

  &__title{
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    margin-bottom: 40px;

    img{
      margin-left: 14px;
    }

  }

  &--red{
    .RoadmapColumn__title{
      color: #F52C57;
    }

    .RoadmapItem:before{
      color: #F52C57;
    }
  }

  &--blue{
    .RoadmapColumn__title{
      color: #4CC0E9;
    }

    .RoadmapItem:before{
      color: #4CC0E9;
    }
  }

  &--green{
    .RoadmapColumn__title{
      color: #3DEBA8;
    }

    .RoadmapItem:before{
      color: #3DEBA8;
    }
  }

  &--yellow{
    .RoadmapColumn__title{
      color: #F5C327;
    }

    .RoadmapItem:before{
      color: #F5C327;
    }
  }
}

.RoadmapItem{
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 19px;
  color: #7A7DA3;
  max-width: 226px;
  margin-bottom: 30px;

  &:before{
    content: '—';
    margin-right: 5px;
  }

  &--indent-1{
    font-size: 14px;
    margin-left: 17px;
    margin-top: -20px;

    &:before{
      content: '—';
      margin-right: 5px;
      font-size: 6px;
    }
  }
}