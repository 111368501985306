.SocialIcons{
  display: flex;
  flex-direction: row;

  .SocialIcon{
    margin-right: 24px;

    &:last-of-type{
      margin-right: 0;
    }

    @include breakpoint("tablet-wide", max){
      margin-right: 12px;
    }
  }
}

.SocialIcon{
  height: 12px;
}