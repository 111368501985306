.EnterprisesPage{

  .HeroContent{
    margin-bottom: 100px;

    @include breakpoint("mobile", max) {
      margin-bottom: 50px;
    }
  }

  .FeaturePoints{
    margin-bottom: 100px;

    @include breakpoint("mobile", max) {
      margin-bottom: 18px;
    }
  }

  .Quotes{
    margin-top: 120px;
    margin-bottom: 150px;

    @include breakpoint("mobile", max) {
      margin-top: 50px;
      margin-bottom: 70px;
    }
  }

  .MainCTAButton{
    margin-right: 32px;
    width: 294px;

    @include breakpoint("mobile", max) {
      width: 240px;
    }
  }

  &__ctas{
    margin-bottom: 150px;

    @include breakpoint("mobile", max) {
      display: flex;
      flex-direction: column;
      margin-bottom: 90px;

      .TextLinkButton{
        margin-top: 28px;
      }
    }
  }
}
