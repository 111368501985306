.CommunityPage{

  &__ctas{
    margin-top: 50px;
    margin-bottom: 50px;

    @include breakpoint("mobile", max) {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .Button{
      width: 200px;
    }
  }

  .HeroContent{
    margin-bottom: 35px;
  }

  .HeroContent__subtitle{
    max-width: 630px;
  }

  &__live-network-stats-section{
    margin-top: 50px;
    margin-bottom: 60px;

    @include breakpoint("mobile", max) {
      margin-bottom: 50px;
      margin-top: 60px;
    }
  }

  &__earn-section{
    margin-top: 100px;
    margin-bottom: 120px;

    @include breakpoint("mobile", max) {
      margin-bottom: 50px;
      margin-top: 60px;
    }
  }

  &__trade-section{
    margin-bottom: 110px;
  }

  &__roadmap-section{
    margin-bottom: 120px;

    .Roadmap{
      margin-top: 50px;

      @include breakpoint("mobile", max) {
        margin-top: 30px;
      }
    }
  }
}

.ExchangeLogos{
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-right: -17px;
  margin-left: -17px;
}

.ExchangeLogo{
  height: 136px;
  width: 296px;
  margin-right: 17px;
  margin-left: 17px;
  margin-bottom: 32px;

  @include breakpoint("mobile", max) {
    height: 48px;
    width: auto;
    margin-right: 4.5px;
    margin-left: 4.5px;
    margin-bottom: 10px;
  }

  img{
    height: 100%;
  }
}

.SocialLogos{
  display: flex;
  flex-wrap: wrap;
}

.SocialLogo{
  height: 60px;
  width: 102px;
  margin-right: 15px;
  margin-bottom: 15px;

  @include breakpoint("mobile", max) {
    height: 32px;
    width: auto;
    margin-right: 6px;
  }

  img{
    height: 100%;
  }
}

.EarnCards{
  display: flex;
  flex-wrap: wrap;
  margin-top: 35px;
  margin-left: -17px;
  margin-right: -17px;

  @include breakpoint("mobile", max) {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
}

.EarnCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 296px;
  height: 525px;
  background-color: #1B1F39;
  padding-top: 22px;
  margin-bottom: 34px;
  margin-left: 17px;
  margin-right: 17px;



  @include breakpoint("mobile", max) {
    width: 160px;
    height: 463px;
    margin-left: 7.5px;
    margin-right: 7.5px;
    margin-bottom: 15px;
  }

  &__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 35px;
    padding-right: 35px;

    @include breakpoint("mobile", max) {
      padding-left: 11px;
      padding-right: 11px;
    }
  }

  &__kind{
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    letter-spacing: 2.8px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 18px;

    @include breakpoint("mobile", max) {
      font-size: 9px;
      line-height: 10px;
      letter-spacing: 1.8px;
      margin-bottom: 9px;
    }
  }

  &__icon{
    height: 70px;
    margin-bottom: 10px;

    @include breakpoint("mobile", max) {
      height: 40px;
      margin-bottom: 4px;
    }
  }

  &__title{
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    height: 97px;

    @include breakpoint("mobile", max) {
      font-size: 11px;
      line-height: 15px;
      height: 54px;
    }
  }

  &__subtitle{
    font-size: 16px;
    line-height: 19px;
    color: #9EA2D4;
    text-align: center;
    height: 70px;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 12px;
      height: 39px;
    }
  }

  &__footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 215px;
    background-color: #222747;
    width: 100%;
    padding-top: 22px;
    padding-bottom: 30px;

    @include breakpoint("mobile", max) {
      height: 285px;
    }
  }

  &__footer-title{
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    color: #9EA2D4;
    text-align: center;

    @include breakpoint("mobile", max) {
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__footer-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 24px;
  }

  &__footer-learn-more-button{
    margin-top: auto;
  }

  .DownloadButton{
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 16px;
  }
}

.LiveNetworkStats{
  display: flex;
  flex-direction: row;
  margin-top: 35px;
}

.LiveNetworkStat{
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-right: 30px;

  &:last-of-type{
    margin-right: 0;
  }

  &__data{
    display: flex;
    flex-direction: column;
  }

  &__amount{
    font-size: 64px;
    font-weight: 300;
    margin-bottom: 100px;
    color: inherit;

    @include breakpoint("mobile", max) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  &__name{
    text-transform: uppercase;
    font-size: 13px;
    font-weight: normal;
    color: white !important;
    letter-spacing: 2.6px;
    line-height: 16px;

    @include breakpoint("mobile", max) {
      font-size: 9px;
      white-space: pre;
    }
  }

  &__line{
    width: 30px;
    margin-right: 15px;

    @include breakpoint("mobile", max) {
      display: none;
    }
  }

  &--yellow{
    color: #F5C327;
  }

  &--green{
    color: #25CED5;
  }

  &--red{
    color: #FF315A;
  }
}