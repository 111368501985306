.terms-page{
    color: #7A7DA3;
    font-size: 18px;

    iframe{
        width: 100%;
        height: calc(100vh - 64px);
        border: none;
      background-color: white;
    }
}