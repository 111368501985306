.Navbar{
  position: fixed;
  position: sticky;
  width: 100%;
  background-color: $color-navbar-background;
  height: 64px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  border-bottom: 1px solid #1E2124;
  max-width: $max-background-width;
  margin-left: auto;
  margin-right: auto;

  @include breakpoint("mobile", max) {
    height: 50px;
  }

  &__content{
    display: flex;
    align-items: center;
    max-width: ($max-width + 24px);
    padding-left: 12px;
    padding-right: 12px;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
  }

  &__links{
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;

    @include breakpoint("mobile", max){
      .NavbarLink{
        display: none;
      }
    }
  }

  &__logo-wrapper{
    display: flex;
    align-items: center;

    @include breakpoint("mobile", max){
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__logo{
    cursor: pointer;
    height: 25px;
    margin-right: 30px;

    @include breakpoint("tablet-wide", max){
      margin-right: 0;
    }
  }

  .SocialIcons{
    margin-left: auto;

    @include breakpoint("mobile", max){
      display: none;
    }
  }
}

.NavbarHamburgerButton{
  position: absolute;
  left: 10px;
  top: 3px;
  height: 44px;
  width: 44px;

  img{
    width: 100%;
  }
}

.NavbarLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  margin-left: 27px;
  position: relative;
  height: 100%;

  @include breakpoint("tablet-wide", max){
    font-size: 14px;
    margin-left: 20px;
  }

  &--explorer{
    flex-direction: row;
    justify-content: flex-start;
  }

  &__external-link{
    width: 7px;
    height: 7px;
    margin-left: 5px;
    margin-bottom: 15px;
    align-self: center;
  }

  &--SubLink{
    font-size: 14px !important;
    margin-left: 30px !important;
    color: #7A7DA3 !important;
  }

  &:hover {
    color: $color-green;
  }

  &__border {
    position: absolute;
    margin-top: 12px;
    height: 1px;
    width: 100%;
    bottom: -1px;
    left: 0;
    right: 0;
    background-color: $color-green;
    display: none;
  }

  &--active{
    .NavbarLink__border{
      display: block;
    }
  }
}

.NavBarMenuHeader{
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 20px;
  padding-right: 20px;
  margin-bottom: 40px;

  &__close{
    width: 44px;
    margin-bottom: 27px;
    margin-left: -12px;
    margin-top: -12px;

    img{
      width: 100%;
    }
  }

  &__social-icons{
    display: flex;
    flex-direction: row;
  }

  .NavBarSocialIcon {
    width: 28px;
    height: 28px;
  }

  .NavbarLink{
    font-size: 18px;
    font-weight: normal;
    line-height: 21px;
    margin-bottom: 32px;
    color: #7A7DA3;
    align-items: unset;

    &--active{
      color: $color-green;

      .NavbarLink__border{
        display: none !important;
      }
    }
  }
}


/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100% !important;
  width: 300px !important;
  top: 0 !important;
  right: initial;
  left: 0 !important;

  [aria-hidden="true"]{
    left: -300 !important;
  }
}

/* General sidebar styles */
.bm-menu {
  background: #0B0B0D !important;
  padding: 0 !important;
  font-size: inherit !important;
  top: 0 !important;

  .NavbarLink{
    margin-left: 20px;
    margin-right: 20px;
    height: 18px;
    margin-bottom: 30px;
    margin-left: 0;
  }
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0 !important;

  .SocialIcons{
    display: block;
    margin-left: 20px;
    margin-right: auto;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.85) !important;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}