.Page{
  // Adjust for navbar height
  //margin-top: 64px;

  @include breakpoint("mobile", max) {
    //margin-top: 50px;
  }
}

.PageSection{
  width: 100%;
  max-width: $max-background-width;
  padding-left: 40px;
  padding-right: 40px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @include breakpoint("mobile", max){
    padding-left: 20px;
    padding-right: 20px;
  }

  // Fix the scrollTo issue being under the navbar when it stops
  padding-top: 64px;
  margin-top: -64px;

  @include breakpoint("mobile", max) {
    padding-top: 50px;
    margin-top: -50px;
  }
}

.PageContent{
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
}