.Metachain{
  display: flex;
  flex-direction: column;
  align-items: center;

  &__visual{
    height: 210px;
    margin-bottom: 22px;

    @include breakpoint("mobile", max){
      height: 130px;
    }
  }

  &__wordmark{
    height: 41px;

    @include breakpoint("mobile", max){
      height: 28px;
    }
  }
}