.Tags{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Tag{
  background-color: #1B1F39;
  color: #847DFF;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 13.75px;
  font-weight: 600;
  margin-right: 16px;
  line-height: 30px;
  margin-bottom: 16px;
  text-transform: uppercase;

  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &--active{
    background-color: #847DFF;
    color: white;
  }
}