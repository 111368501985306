.HomePage{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 122px;

  @include breakpoint("mobile", max){
    padding-top: 38px;
  }

  .HeroCountdownContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    max-width: 476px;
    padding-top: 24px;
    padding-bottom: 18px;
    margin-top: 36px;

    &__title{
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 12px;
      text-align: center;
      white-space: pre;
      color: #9EA2D4;

      @include breakpoint("mobile", max){
        font-size: 14px;
      }
    }

    .Countdown{
      color: #ffffff;

      @include breakpoint("mobile", max){

      }
    }

    .TextLinkButton{
      margin-top: 15px;
    }
  }

  &__main-title-and-subtitle{
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    //min-height: 310px;

    @include breakpoint("mobile", max){
      margin-bottom: 10px;
      //min-height: 180px;
    }
  }

  &__main-title{
    font-weight: bold;
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    letter-spacing: 0;
    color: #FFFFFF;
    max-width: 1100px;
    max-width: 950px;
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint("mobile", max){
      font-size: 34px;
      line-height: 39px;
      margin-bottom: 16px;
      max-width: 335px;
    }
  }

  &__main-title-tagline{
    color: $color-green;
    min-height: 80px;

    @include breakpoint("mobile", max){
      min-height: 39px;
    }
  }

  &__main-subtitle{
    font-size: 21px;
    font-weight: 500;
    line-height: 31px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    white-space: pre;

    @include breakpoint("mobile", max){
      font-size: 14px;
      line-height: 18px;
    }
  }

  .HeroSection{
    padding-top: 0;

    @include breakpoint("mobile", max){
      padding-top: 40px;
    }

    &__trusted-by{
      font-size: 18px;
      font-weight: bold;
      color: white;
      text-align: center;
      margin-top: 38px;
      margin-bottom: 24px;

      @include breakpoint("mobile", max){
        font-size: 14px;
        margin-top: 22px;
        margin-bottom: 20px;
      }
    }
  }

  .VideoInfrastructureSection{
    margin-top: 130px;

    @include breakpoint("mobile", max){
      margin-top: 70px;
    }
  }

  .NewsSection{
    margin-top: 90px;
    align-items: center;

    @include breakpoint('mobile'){
      margin-top: 90px;
    }

    &__view-all-news{
      margin-top: 12px;
      font-size: 18px;
    }

    .NewsArticles{
      margin-top: 60px;

      // Only show one news article
      @include breakpoint("mobile", max){
        justify-content: center;
      }
    }
  }

  .NodesMapSection{
    margin-top: 100px;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint('mobile'){
      margin-top: 40px;
    }

    .SectionTitle{
      margin-bottom: 30px;
    }
  }
}


.UpcomingRelease{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  &__edgecloud-image{
    height: 85px;

    @include breakpoint("mobile", max){
      height: 47px;
      margin-top: 30px;
    }
  }

  &__launch-date{
    font-size: 13px;
    font-weight: 600;
    color: white;
    letter-spacing: 1.3px;
    text-transform: uppercase;

    @include breakpoint("mobile", max){
      font-size: 11px;
      letter-spacing: 1.1px;

    }
  }

  &__title{
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px;
    text-align: center;
    white-space: pre;

    @include breakpoint("mobile", max){
      font-size: 14px;
    }
  }

  .Countdown{
    margin-top: 24px;
    margin-bottom: 6px;
    color: #ffffff;

    @include breakpoint("mobile", max){
      margin-top: 22px;
    }
  }

  .TextLinkButton{
    margin-top: 15px;
  }
}



.InfrastructureItems{
  display: flex;
  flex-direction: column;
  max-width: 1050px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  position: relative;

  &.MobileLayout{
    display: none;
  }

  @include breakpoint("mobile", max){
    // Hide the desktop size
    display: none;

    //Reduce width
    max-width: 380px;

    &.MobileLayout{
      display: flex;
    }
  }
}

.InfrastructureItemsRow{
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 28px;
}

.InfrastructureItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 227px;

  @include breakpoint("mobile", max) {
    max-width: 150px;
  }

  &__icon{
    width: 62px;
    height: 62px;
    margin-bottom: 12px;

    @include breakpoint("mobile", max) {
      width: 32px;
      height: 32px;
    }
  }

  &__title{
    font-size: 18px;
    font-weight: bold;
    line-height: 21px;
    margin-bottom: 8px;
    text-align: center;

    @include breakpoint('mobile'){
      font-size: 12px;
      line-height: 14.4px;
    }
  }

  &__subtitle{
    font-size: 14px;
    font-weight: normal;
    line-height: 17px;
    margin-bottom: 8px;
    color: $color-text-dark;
    text-align: center;
    max-width: 200px;

    @include breakpoint('mobile'){
      font-size: 10px;
      line-height: 12.6px;
      max-width: 130px;
    }
  }
}

.EdgeNodeDownloadCTA{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 738px;
  margin-top: 60px;

  &__grey-title{
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #9EA2D4;
  }

  &__title{
    font-size: 22px;
    font-weight: bold;
    line-height: 26px;
    color: white;
    margin-bottom: 35px;
    text-align: center;
    white-space: pre-line;

    @include breakpoint('mobile'){
      font-size: 18px;
      line-height: 22px;
      max-width: 300px;
    }

    img{
      height: 18px;
      width: 18px;
      margin-left: 10px;
      margin-right: 5px;
    }
  }

  &__subtitle{
    font-size: 14px;
    font-weight: 600;
    color: #9EA2D4;
    margin-bottom: 20px;
  }

  &__buttons{
    display: flex;
    flex-direction: row;

    @include breakpoint('mobile'){
      width: 100%;
      justify-content: center;
      flex-wrap: wrap;
      max-width: 300px;
    }

    .DownloadButton{
      border-color: transparentize(#847DFF, 0.5);
      margin-left: 6px;
      margin-right: 6px;
      margin-bottom: 6px;
    }
  }
}

.CustomerLogoSlide{
  height: 160px;
  width: 160px;
  background-color: rgba(255, 255, 255, 0.05);
  margin-left: 5px;
  margin-right: 5px;

  img{
    width: 100%;
    height: 100%;
  }

  @include breakpoint("mobile", max){
    height: 100px;
    width: 100px;
  }
}