// Vars
@import "vars";

// Utils
@import "utils/reset";
@import "utils/responsive";
@import "utils/typography";

// Components
@import "components/Page";
@import "components/SectionTitle";
@import "components/Navbar";
@import "components/Button";
@import "components/Loader";
@import "components/PageBackground";
@import "components/Metachain";
@import "components/NewsArticle";
@import "components/Quote";
@import "components/SocialIcons";
@import "components/HeroContent";
@import "components/FeaturePoint";
@import "components/PartnerLogos";
@import "components/Roadmap";
@import "components/PersonCard";
@import "components/Footer";
@import "components/NewsBanner";
@import "components/Tag";
@import "components/Countdown";
@import "components/NodesMap";

// Pages
@import "pages/Home";
@import "pages/Enterprises";
@import "pages/Developers";
@import "pages/Ecosytem";
@import "pages/Community";
@import "pages/Wallet";
@import "pages/Docs";
@import "pages/About";
@import "pages/JobPosting";
@import "pages/OfficialLinks";
@import "pages/Terms";

// Modals

body {
  margin: 0;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: $color-background;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.App{

  &--loading{
    height: 100vh;
    display: flex;
  }

  &--dashboard{
    min-width: 850px;
  }
}


#root{

}
