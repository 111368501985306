.FeaturePoints{
  display: flex;
  flex-direction: row;

  @include breakpoint("mobile", max) {
    flex-direction: column;
  }
}

.FeaturePoint{
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 84px;

  @include breakpoint("tablet-wide", max){
    margin-right: 30px;
  }

  @include breakpoint("mobile", max) {
    margin-right: 0;
    margin-bottom: 30px;
    max-width: 400px;
  }

  &__icon-wrapper{
    display: flex;
    align-items: center;
    height: 58px;
    margin-bottom: 15px;

    @include breakpoint("mobile", max) {
      //height: 34px;
      margin-bottom: 8px;
    }
  }

  &__icon{
    height: 100%;
  }

  &__title{
    font-size: 16px;
    line-height: 19px;
  }
}