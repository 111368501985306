.HeroContent{
  display: flex;
  flex-direction: column;
  padding-top: 75px;

  @include breakpoint("mobile", max) {
    padding-top: 18px;
  }

  &__category{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 10px;

    @include breakpoint("mobile", max) {
      font-size: 11px;
    }

    &:after{
      content: "";
      height: 1px;
      width: 100px;
      background-color: white;
      margin-left: 15px;
    }
  }

  &__title{
    font-size: 70px;
    font-weight: normal;
    line-height: 80px;
    max-width: 750px;

    @include breakpoint("mobile", max) {
      font-size: 32px;
      line-height: 38px;
    }
  }

  &__subtitle{
    font-size: 18px;
    font-weight: normal;
    line-height: 21px;
    color: transparentize(white, 0.5);
    margin-top: 25px;
    max-width: 400px;
    white-space: pre-wrap;

    @include breakpoint("mobile", max) {
      font-size: 16px;
      line-height: 19px;
    }

    a{
      //color: $color-green;
      font-weight: 600;
      color: transparentize(white, 0.35);
    }
  }

  &--red{
    .HeroContent__title{
      color: #F52C57;
    }

    .HeroContent__category:after{
      background-color: #F52C57;
    }
  }

  &--blue{
    .HeroContent__title{
      color: #4CC0E9;
    }

    .HeroContent__category:after{
      background-color: #4CC0E9;
    }
  }

  &--orange{
    .HeroContent__title{
      color: #FF570F;
    }

    .HeroContent__category:after{
      background-color: #FF570F;
    }
  }

  &--pink{
    .HeroContent__title{
      color: #C90A74;
    }

    .HeroContent__category:after{
      background-color: #C90A74;
    }
  }

  &--purple{
    .HeroContent__title{
      color: #847DFF;
    }

    .HeroContent__category:after{
      background-color: #847DFF;
    }
  }

  &--teal{
    .HeroContent__title{
      color: #00A99D;
    }

    .HeroContent__category:after{
      background-color: #00A99D;
    }
  }
}